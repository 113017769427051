.userTableDiv {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userTableToolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.searchField {
  width: 250px;
}
.userTableButton {
  width: 100px;
  font-family: inherit;
}
.userTableButton:disabled {
  cursor: not-allowed;
  background-color: gray !important;
  border-color: gray !important;
}
.userTableButtonSmall {
  width: 80px;
}
.btn-info:disabled {
  background-color: lightgray;
}
.userTableInactive td {
  background-color: lightcoral !important;
}
.userTableDiv td {
  padding: 6px 8px;
  vertical-align: baseline;
}
.userTableDiv td:nth-child(1) {
  width: 60px;
}
/* td:nth-child(2) {
     width: 200px;
 } */
.userTableDiv td:nth-child(3) {
  width: 100px;
}
.userTableDiv td:nth-child(4) {
  width: 100px;
}
.userTableDiv td:nth-child(5) {
  width: 100px;
}
.userTableDiv td:nth-child(6) {
  width: 330px;
}
.paginator {
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.paginatorLabel {
  margin: 0 10px;
  text-align: center;
}
.paginatorButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.paginatorButtonLong {
  width: 60px;
}
.paginatorSpinner {
  margin-top: 10px;
  text-align: center;
}
