.root {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: steelblue;
  z-index: 100;
}
.content {
  padding: 8px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 54px;
}
.title {
  color: white;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}
